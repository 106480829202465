import React from 'react';
import Layout from '../components/layout';
import styled from '@emotion/styled';
import { Text, Flex } from 'theme-ui';

const AboutContainer = styled(Flex)`
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;

  p {
    line-height: 1.4rem;
    //text-align-last: justify;
    text-align: justify;
  }
`;

const About = () => {
  return (
    <Layout>
      <Text
        as="h1"
        mb={[3]}
        sx={{ textTransform: 'uppercase', textAlign: 'center' }}
      >
        About
      </Text>
      <AboutContainer>
        <Text as="p" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
          Who are we?
        </Text>
        <Text as="p" mb={[3]} sx={{ textAlign: 'left' }}>
          We are a Las Vegas-based manufacturer of industrial-strength Ultra
          Violet light sanitizing machines.
        </Text>
        <Text as="p" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
          What is the goal of HyperUV?
        </Text>
        <Text as="p" mb={[3]} sx={{ textAlign: 'left' }}>
          We want to show how businesses would benefit from using our machine to
          reduce possible spread of COVID-19.
          <br />
          We have the most powerful Ultra Violet light machine on the market
          that can reduce the spread of COVID-19 virus and it will enable
          America to re-open for work and business.
        </Text>
        <Text as="p" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
          What is the purpose of the HyperUV device?
        </Text>
        <Text as="p" mb={[1]} sx={{ textAlign: 'left' }}>
          Our machine is the brand-new, made in America, triple-function,
          portable, high-output, Industrial-Sized, Ultra Violet Light-(UV-C)
          machine that is manufactured in Las Vegas. A published study in the
          Nevada Journal of Public Health, with the study done at UNLV,
          documented the effectiveness against bacteria. Viruses are extremely
          susceptible to eradication by UV-C light.
        </Text>
        <Text as="p" mb={[3]} sx={{ textAlign: 'left' }}>
          Hyper Medical Technologies Inc. designed and manufactures the
          industry-leading, exclusive Triple-function, high-output, portable
          Ultra Violet-C (UVC light) sanitizing machine capable of cleansing the
          surfaces and air directly with high-power UVC light and continuous air
          filtration with HEPA and UVC in an internal wind tunnel.
        </Text>
        <Text as="p" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
          What are each of the triple-functions?
        </Text>
        <Text as="p" mb={[3]} sx={{ textAlign: 'left' }}>
          <span style={{ fontWeight: 'bold' }}>(1) &nbsp;</span> Direct UVC
          light on surfaces and surrounding air
          <br />
          <span style={{ fontWeight: 'bold' }}>(2)</span> Air filtration through
          a HEPA fileter, and UV-C concentrated light irradiation in a central
          wind tunnel in the middle of the machine that performs high-flow
          continuous sanitizing of air
          <br />
          <span style={{ fontWeight: 'bold' }}>(3)</span> Special portable
          reflective barrier to allow sanitizing of isolated area or for
          sanitizing work equipment or PPE's (personal protective equipment)
        </Text>
        <Text as="p" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
          What are a few potential life-saving usage examples?
        </Text>
        <Text as="p" mb={[4]} sx={{ textAlign: 'left' }}>
          Consider the use of our machine in the businesses, offices, lobbies,
          waiting rooms, casinos, restaurants, airports, hotels, cruise ships,
          airline planes, buses, trains, subways, classrooms, nursing homes,
          clinics, hospitals, to keep the customers and staff safer with reduced
          infection risk. This is not a medical equipment, but a general public
          house-keeping, sanitizing equipment that will help America re-open for
          work.
        </Text>
        <Text as="p" mb={[2]} sx={{ textAlign: 'left' }}>
          Please contact (888) 525-0805 for more information.
        </Text>
        <Text as="p" mb={[3]} sx={{ textAlign: 'left' }}>
          Thomas Yee, MD
          <br />
          CEO
          <br />
          Hyper Medical Technologies, Inc.
          <br />
          http://www.hypermedtech.com/
        </Text>
      </AboutContainer>
    </Layout>
  );
};

export default About;
